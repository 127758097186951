<script setup>
// import { ref } from "vue";
const user = JSON.parse(sessionStorage.getItem("user"));
</script>

<template>
  <div class="landing">
    <div class="landing__header">
      <h1>Виджет для массового размещения через Маркет-платформу ВК</h1>
      <p>
        Ускорь работу с Маркет-платформой в 3 раза, за счет виджета для
        массового размещения только в нужных тебе группах
      </p>
      {{ user }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.landing {
  min-height: calc(100vh - 125px);
  &__header {
    width: 100%;
    max-width: 1200px;
    padding: 15px;
    text-align: center;
  }
}
</style>
