<template>
  <div class="app-footer">
    <div class="app-footer__inner">
      <div class="app-footer__content">
        © TurboMP 2023 - {{ new Date().getFullYear() }}
      </div>
      <nav class="app-footer__nav">
        <ul>
          <!-- <li>Самозанятый: Давыдов Дмитрий Вячеславович. ИНН: 930901231279</li> -->
          <!-- <li>
            <a href="https://vk.com/t_davydova" target="_blank">Вконтакте</a>
          </li>
          <li>
            <a href="https://t.me/td_davydovy" target="_blank">Телеграм</a>
          </li> -->
          <li>
            <router-link to="/contacts">Контакты</router-link>
          </li>
          <li>
            <router-link to="/privacy">Пользовательское соглашение</router-link>
          </li>
          <li>
            <router-link to="/oferta">Оферта</router-link>
          </li>

          <!-- <li><router-link to="/crm">Ручные посевы</router-link></li> -->
        </ul>
      </nav>
    </div>
  </div>
</template>

<style lang="scss">
.app-footer {
  background: #2d2d2d;
  width: 100%;
  &__inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #eee;
  }
  border-top: 1px solid #dcdcdc;

  &__nav {
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin-left: 30px;
        a {
          color: #eee;
          transition: 0.3s;
          text-decoration: none;
          &:hover {
            transition: 0.3s;
            // color: #027dee;
            color: #1fc3b0;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
