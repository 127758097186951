<!-- /views/LoginVuew.vue -->
<template>
  <div>
    <div class="user-info">
      <div v-if="!!currentUser" class="user-info__name">
        <span
          >{{ currentUser.name }} <br />
          <b class="user-info__vk-id">vk-id: {{ currentUser.id }}</b></span
        >
        <img
          v-if="!!currentUser"
          width="60"
          height="60"
          class="user-info__avatar"
          :src="currentUser.avatar"
        />
      </div>

      <button
        v-if="isAuthenticated"
        class="user-info__login-button"
        @click="handleLogoutClick"
      >
        Выйти
      </button>
      <button v-else class="user-info__login-button" @click="handleLoginClick">
        Войти
      </button>
    </div>
  </div>
</template>

<script>
import { Config, Connect } from "@vkontakte/superappkit";
import axios from "axios";
import { mapGetters } from "vuex";
Config.init({
  appId: 52243729, // Замените на ID вашего приложения VK.
});

export default {
  data() {
    return {
      userData: null,
      uuid: null,
      silent_token: null,
      auth: null,
      access_token: null,
      csrf_token: null,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
  },
  methods: {
    async handleLoginClick() {
      try {
        const redirectUrl = "https://turbomp.ru";
        // const redirectUrl = "https://smartbizonline.ru";
        await Connect.redirectAuth({
          url: redirectUrl,
          state: "",
          source: "",
          action: undefined,
          screen: undefined,
        });
      } catch (error) {
        console.error("Ошибка авторизации:", error);
      }
    },
    handleLogoutClick() {
      this.$store.commit("logout");
      this.$router.push("/");
    },
    async checkUrlForUserData() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("payload")) {
        const payload = JSON.parse(decodeURIComponent(params.get("payload")));
        this.userData = payload.user;
        this.uuid = payload.uuid;
        this.silent_token = payload.token;
        this.auth = payload.auth;
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
        try {
          const response = await axios.post("/backend/auth-callback.php", {
            user: this.userData,
            silent_token: this.silent_token,
            uuid: this.uuid,
          });

          if (response.data.success) {
            console.log(response.data);

            // Вызываем мутацию login из хранилища
            this.$store.commit("login", {
              access_token: response.data.data.access_token,
              csrf_token: response.data.data.csrf_token,
              user: {
                name: this.userData.first_name,
                avatar: this.userData.avatar,
                id: this.userData.id,
                uuid: this.uuid,
                // ... другие поля, если необходимо ...
              },
            });

            // Перенаправляем на другую страницу, например, на домашнюю
            this.$router.push("/");
          } else {
            // Данные устарели или невалидны, перенаправьте на страницу авторизации
            // this.$router.push("/login");
            this.$store.commit("logout");
            alert("Сессия истекла. Авторизуйтесь, пожалуйста, повторно.");
            this.$router.push("/");
          }
        } catch (error) {
          // Ошибка запроса или проверки, перенаправьте на страницу ошибки
          // this.$router.push("/error");
          this.$store.commit("logout");
          // alert(error);
          alert("Ошибка. Авторизуйтесь, пожалуйста, повторно.");
          this.$router.push("/");
        }
      }
    },
  },

  mounted() {
    this.checkUrlForUserData();
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__name {
    margin-right: 15px;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 30px;
    background: #f9f9ff;
    border-radius: 50px;
  }
  &__vk-id {
    font-size: 12px;
    color: #686868;
    font-weight: 500;
  }
  &__avatar {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-left: 15px;
  }
  &__login-button {
    height: 50px;
    width: 110px;
    padding: 0 30px;
    border-radius: 30px;
    border: none;
    background: #027dee;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      transition: 0.3s;
      background: darken(#027dee, 7%);
    }
  }
}
</style>
