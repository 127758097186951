import axios from "axios";
import store from "@/store";

export const requireAuth = async (to, from, next) => {
  if (!store.state.isAuthenticated) {
    console.log("Не авторизован, перенаправляем на /");
    next("/");
  } else {
    try {
      const axiosConfig = {
        withCredentials: true, // Включаем передачу сессионных cookie
        headers: {
          "X-CSRF-Token": store.getters.csrfToken, // Добавляем заголовок с CSRF-токеном
        },
      };

      const response = await axios.post(
        "/backend/check-session.php",
        {
          user_id: JSON.parse(sessionStorage.getItem("user")).id,
        },
        axiosConfig
      );

      const { success, data } = response.data;

      if (success) {
        // Проверка данных сессии успешна, разрешаем навигацию
        store.commit("login", {
          access_token: data.access_token,
          csrf_token: data.csrf_token,
          user: {
            name: data.user.user_name,
            avatar: data.user.user_avatar,
            id: data.user.user_id,
            uuid: data.uuid,
          },
        });
        next();
      } else {
        console.log("Данные сессии недействительны, перенаправляем на /");
        store.commit("logout");
        next("/");
      }
    } catch (error) {
      console.error("Ошибка во время проверки сессии:", error);
      store.commit("logout");
      next("/error");
    }
  }
};
